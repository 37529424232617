

























































































































































import { Component, Vue } from "vue-property-decorator";
import { TimelineMax } from "gsap";
import Rellax from "rellax";

interface MovingItem {
  image: string;
  className: string;
  isMoving: boolean;
  direction: "top" | "bottom" | "left" | "right";
  parallaxSpeed?: number;
}

interface Pizza {
  title: string;
  price: number;
  image: string;
  alternativeImg: string;
  ingredients: string[];
  movingItems?: MovingItem[];
}

interface HeroDescriptionItem {
  title: string;
  image: string;
  description?: string;
  headItem?: boolean;
  link?: string;
  action?: Function;
}

interface NavItem {
  title: string;
  link: string;
}

@Component
export default class Hero extends Vue {
  activePizzaIndex = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bounce: any = {};

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rellax = new Rellax(".rellax");
    this.startAnimation();
  }

  openVideoModal() {
    this.videoModal = true;
    document.body.style.overflow = "hidden";
  }

  get isNavOpen() {
    return this.$store.getters.isNavOpen;
  }

  set isNavOpen(value) {
    this.$store.commit("setNav", value);
  }

  get currentPizza(): Pizza {
    return this.pizzas[this.activePizzaIndex];
  }

  get videoModal() {
    return this.$store.getters.videoModal;
  }

  set videoModal(value) {
    this.$store.commit("setVideoModal", value);
  }

  pizzas: Pizza[] = [
    {
      title: "Parma",
      price: 38,
      image: "parma.webp",
      alternativeImg: "parma.png",
      ingredients: [
        "pomidory San Marzano D.O.P.",
        "prossciutto crudo",
        "rukola",
        "pomidorki cherry",
        "sos balsamiczny",
        "parmezan D.O.P."
      ],
      movingItems: [
        {
          image: "olives.png",
          className: "olives",
          isMoving: true,
          direction: "left",
          parallaxSpeed: 10
        },
        {
          image: "cheese1.png",
          className: "cheese1",
          isMoving: true,
          direction: "left"
        },
        {
          image: "desk.png",
          className: "desk",
          isMoving: false,
          direction: "bottom"
        },
        {
          image: "pepper1.png",
          className: "pepper1",
          isMoving: false,
          direction: "bottom"
        },
        {
          image: "rocket1.png",
          className: "rocket1",
          isMoving: true,
          direction: "bottom"
        },
        {
          image: "basil3.png",
          className: "basil3",
          isMoving: true,
          direction: "bottom",
          parallaxSpeed: 10
        },
        {
          image: "basil4.png",
          className: "basil4",
          isMoving: true,
          direction: "bottom",
          parallaxSpeed: 10
        },
        {
          image: "cheese2.png",
          className: "cheese2",
          isMoving: true,
          direction: "bottom"
        },
        {
          image: "rocket3.png",
          className: "rocket3",
          isMoving: true,
          direction: "right"
        },
        {
          image: "rocket2.png",
          className: "rocket2",
          isMoving: true,
          direction: "right"
        },
        {
          image: "pepper2.png",
          className: "pepper2",
          isMoving: true,
          direction: "right"
        }
      ]
    },
    {
      title: "Prosciutto cotto e fungi",
      price: 32,
      image: "funghi.webp",
      alternativeImg: "funghi.png",
      ingredients: [
        "pomidory San Marzano D.O.P.",
        "mozzarella",
        "szynka",
        "pieczarki"
      ],
      movingItems: [
        {
          image: "champ1.png",
          className: "champ1",
          isMoving: true,
          direction: "left"
        },
        {
          image: "cotto.png",
          className: "cotto",
          isMoving: true,
          direction: "bottom"
        },
        {
          image: "tomatoes.png",
          className: "tomatoes",
          isMoving: false,
          direction: "top"
        },
        {
          image: "champ2.png",
          className: "champ2",
          isMoving: true,
          direction: "right"
        },
        {
          image: "olives.png",
          className: "olives",
          isMoving: true,
          direction: "left",
          parallaxSpeed: 10
        },
        {
          image: "pepper1.png",
          className: "pepper1",
          isMoving: false,
          direction: "bottom"
        },
        {
          image: "mozarella1.png",
          className: "mozarella1",
          isMoving: true,
          direction: "right"
        },
        {
          image: "mozarella2.png",
          className: "mozarella2",
          isMoving: true,
          direction: "bottom"
        },
        {
          image: "pepper2.png",
          className: "pepper2",
          isMoving: true,
          direction: "top"
        }
      ]
    },
    {
      title: "Capresse",
      price: 34,
      image: "capresse.webp",
      alternativeImg: "capresse.png",
      ingredients: [
        "pomidory San Marzano D.O.P.",
        "świeży pomidor malinowy",
        "świeża mozzarella",
        "czarne oliwki",
        "pesto",
        "bazylia"
      ],
      movingItems: [
        {
          image: "tomato1.png",
          className: "tomato1",
          isMoving: true,
          direction: "left"
        },
        {
          image: "lamblettuce1.png",
          className: "lamblettuce1",
          isMoving: true,
          direction: "left"
        },
        {
          image: "pepper.png",
          className: "pepper",
          isMoving: true,
          direction: "left"
        },
        {
          image: "tomato2.png",
          className: "tomato2",
          isMoving: true,
          direction: "bottom"
        },
        {
          image: "tomatoes.png",
          className: "tomatoes",
          isMoving: false,
          direction: "top"
        },
        {
          image: "basil3.png",
          className: "basil3",
          isMoving: true,
          direction: "bottom"
        },
        {
          image: "lamblettuce2.png",
          className: "lamblettuce2",
          isMoving: true,
          direction: "bottom"
        },
        {
          image: "pepper1.png",
          className: "pepper1",
          isMoving: false,
          direction: "bottom"
        },
        {
          image: "tomato3.png",
          className: "tomato3",
          isMoving: true,
          direction: "right"
        },
        {
          image: "mozarella1.png",
          className: "mozarella1",
          isMoving: true,
          direction: "right"
        },
        {
          image: "mozarella2.png",
          className: "mozarella2",
          isMoving: true,
          direction: "bottom"
        },
        {
          image: "lamblettuce3.png",
          className: "lamblettuce3",
          isMoving: true,
          direction: "right"
        },
        {
          image: "tomato4.png",
          className: "tomato4",
          isMoving: true,
          direction: "top"
        },
        {
          image: "pepper2.png",
          className: "pepper2",
          isMoving: true,
          direction: "top"
        }
      ]
    }
  ];

  heroDescription: HeroDescriptionItem[] = [
    {
      title: "Gdzie zjeść i gdzie dowozimy?",
      image: "pin.png",
      headItem: true,
      action: () =>
        window.open("https://goo.gl/maps/zNQLCCmCsk6vTnJP6", "_blank")?.focus()
    },
    {
      title: "Film",
      image: "video.png",
      description: "Ekskluzywne nagrania tworzenia pizzy",
      action: this.openVideoModal
    },
    {
      title: "Zdjecia",
      image: "camera.png",
      description: "Zobacz jak jedzą nasi klienci",
      action: () => this.$router.push("/klienci")
    },
    {
      title: "Składniki",
      image: "list.png"
    }
  ];

  updatePizzaIndex() {
    if (this.activePizzaIndex < this.pizzas.length - 1) this.activePizzaIndex++;
    else this.activePizzaIndex = 0;
  }

  startAnimation() {
    const pizza = this.$refs.pizza;
    const base = this.$refs.base;
    const desc = this.$refs.desc;
    const staticNav = this.$refs.staticNav;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const movingItem: any = this.$refs.movingItem;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const movingWrapper: any = this.$refs.movingWrapper;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const items: any = [].slice.call(movingItem);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const wrappersArray: any = [].slice.call(movingWrapper);

    const tl = new TimelineMax();

    tl.set(items, {
      autoAlpha: 0,
      y: 100,
      delay: 1
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (tl as any).staggerTo(
      items,
      1.5,
      {
        ease: "expo",
        autoAlpha: 1,
        y: 0
      },
      0.15,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      wrappersArray.forEach(async (item: any) => {
        item.style.setProperty(
          "animation-delay",
          this.getRandomInt(0, 8) / 4 + "s"
        );
        item.style.setProperty(
          "animation-duration",
          this.getRandomInt(4, 8) / 4 + "s"
        );
        item.style.setProperty("animation-name", "bounce");
      })
    );

    tl.from(
      base,
      {
        duration: 1.5,
        opacity: 0,
        ease: "power4",
        y: -50
      },
      "-=2.5"
    );

    tl.from(
      pizza,
      {
        duration: 2,
        opacity: 0,
        ease: "expo",
        y: -150
      },
      "-=2"
    );

    tl.from(
      desc,
      {
        duration: 1.5,
        opacity: 0,
        ease: "power4",
        y: -50
      },
      "-=1"
    );

    tl.from(
      staticNav,
      {
        duration: 2,
        opacity: 0,
        ease: "expo",
        y: 150
      },
      "-=2"
    ).to(staticNav, {
      opacity: 1,
      y: 0,
      delay: 1
    });

    const delay = 8;
    tl.addPause(delay, () => this.changeAnimation());
  }

  changeAnimation() {
    const pizza = this.$refs.pizza;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const movingItem: any = this.$refs.movingItem;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const items: any = [].slice.call(movingItem);
    const delay = 8;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const movingWrapper: any = this.$refs.movingWrapper;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const wrappersArray: any = [].slice.call(movingWrapper);

    const tl = new TimelineMax({ repeat: -1, repeatDelay: delay });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (tl as any).staggerTo(
      items,
      2,
      {
        autoAlpha: 0,
        opacity: 0,
        ease: "expo",
        y: 100
      },
      0.1
    );

    tl.from(
      pizza,
      {
        duration: 2,
        opacity: 1,
        ease: "expo",
        y: 0
      },
      "-=5"
    ).to(
      pizza,
      {
        opacity: 0,
        y: 50,
        onComplete: () => this.updatePizzaIndex()
      },
      "-=1.5"
    );

    tl.set(items, { autoAlpha: 0, y: 100 });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (tl as any).staggerTo(
      items,
      1.5,
      {
        autoAlpha: 1,
        ease: "expo",
        y: 0,
        delay: 1
      },
      0.15,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      wrappersArray.forEach(async (item: any) => {
        item.style.setProperty(
          "animation-delay",
          this.getRandomInt(0, 8) / 4 + "s"
        );
        item.style.setProperty(
          "animation-duration",
          this.getRandomInt(4, 8) / 4 + "s"
        );
        item.classList.add("bounce");
      })
    );

    tl.from(
      pizza,
      {
        duration: 2,
        opacity: 0,
        ease: "expo",
        y: -150
      },
      "-=3"
    );
  }

  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }
}
