















import { Vue, Component } from "vue-property-decorator";

@Component
export default class YoutubePlayer extends Vue {
  videoId = "yapn5Em2ftE";
  closeModal() {
    this.videoModal = false;
    document.body.style.overflow = "auto";
  }

  get videoModal() {
    return this.$store.getters.videoModal;
  }

  set videoModal(value) {
    this.$store.commit("setVideoModal", value);
  }
}
