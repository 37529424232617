


















import { Vue, Component } from "vue-property-decorator";
import LoadingOverlay from "./components/LoadingOverlay.vue";
import TheNavbar from "./components/TheNavbar.vue";
import NavOverlay from "./components/NavOverlay.vue";
import TheFooter from "./components/TheFooter.vue";

@Component({
  components: { LoadingOverlay, TheNavbar, NavOverlay, TheFooter }
})
export default class App extends Vue {
  get isNavOpen(): boolean {
    return this.$store.getters.isNavOpen;
  }
}
