




































































































































import { Component, Vue } from "vue-property-decorator";
import Hero from "@/components/Hero.vue";
import YoutubePlayer from "@/components/YoutubePlayer.vue";

interface IntroductionItem {
  name: string;
  title: string;
  subTitle: string;
  paragraphs: string[];
  image: string;
  backgroundColor: string;
}

@Component({
  components: { Hero, YoutubePlayer }
})
export default class Home extends Vue {
  async created() {
    await this.$store.dispatch("fetchPizzaOfTheMonth");
    this.overlayLoading = false;
  }

  get displayOnMobile() {
    const mq = window.matchMedia("(max-width: 450px)");
    return mq.matches;
  }

  socialsItems = [
    {
      icon: "ig_black.png",
      link: "https://www.instagram.com/tenisplanetsmolec/"
    },
    {
      icon: "fb_black.png",
      link: "https://www.facebook.com/tenisplanetsmolec/"
    },
    {
      icon: "yt_black.png",
      link: "https://www.youtube.com/channel/UCKD_wDLcAK9FTLmjDOAyHhQ"
    }
  ];

  introductionItems: IntroductionItem[] = [
    {
      name: "introduction",
      title: "Witaj w naszej restauracji",
      subTitle:
        "tu gdzie łączymy pasję z rodzinną atmosferą i pysznym smakiem!",
      paragraphs: [
        "Naszą misją jest, aby każdy czuł się jak u siebie w domu. Rodzina odgrywa niezwykle istotną rolę w życiu każdego człowieka. To wśród najbliższych nabywamy podstawową wiedzę i umiejętności niezbędne do funkcjonowania w rzeczywistości społecznej.",
        "Rodzice i krewni stają się przykładem dla dzieci, które, jak wiadomo nie od dziś, najszybciej uczą się poprzez obserwację i naśladownictwo dorosłych."
      ],
      image: "slide1.jpg",
      backgroundColor: "white"
    },
    {
      name: "location",
      title: "Nasze menu na miejscu",
      subTitle: "Pozwól ugościć się naszym kucharzom.",
      paragraphs: [
        "Tradycyjne receptury, przekazywane z pokolenia na pokolenie, staranny dobór składników i kadra kucharzy z pasją, pozwalają częstować naszych klientów daniami o wspaniałym smaku.",
        "Zapraszamy do zamawiania naszej pizzy w dostawie, a w ofercie stacjonarnej również menu śniadań, lunchy i orzeźwiających selekcjonowanych piw."
      ],
      image: "slide2.jpg",
      backgroundColor: "#454545"
    },
    {
      name: "delivery",
      title: "Nasze menu na dowóz",
      subTitle: "Poznaj nasze smaki i zostań z nami na stałe.",
      paragraphs: [
        "Z poszanowaniem dla Państwa, w naszym menu na dowóz, poza pizzą znalazły się tylko wyselekcjonowane dania, które jesteśmy Państwu dostarczyć w tak krótkim czasie aby nie utraciły one swojego smaku i aromatu. Nie zależy nam na ilości, najważniejsza jest jakość i Państwa zadowolenie. Nasi kucharze i pizzaiolo są specjalistami w swym fachu i chcemy aby ich praca nie szła na marne. Mamy nadzieję, że będziecie mogli docenić ich kunszt.",
        "Ze swojej strony prosimy o wyrozumiałość w sytuacjach losowych, gdyż na pewne jak korki czy inne zdarzenia drogowe nie mamy wpływu. Prosimy pamiętać iż żadna potrawa w dostawie nie będzie tak smakować jak ta podana na miejscu. Zapraszamy więc serdecznie."
      ],
      image: "slide3.jpg",
      backgroundColor: "white"
    }
  ];

  get pizzaOfTheMonth() {
    return this.$store.getters.pizzaOfTheMonth;
  }

  set pizzaOfTheMonth(value) {
    this.$store.commit("setPizzaOfTheMonth", value);
  }

  get loading() {
    return this.$store.getters.loading;
  }

  get overlayLoading() {
    return this.$store.getters.overlayLoading;
  }

  set overlayLoading(value) {
    this.$store.commit("setOverlayLoading", value);
  }

  get videoModal() {
    return this.$store.getters.videoModal;
  }
}
