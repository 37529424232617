






























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class TheFooter extends Vue {
  get socialsItems() {
    return this.$store.getters.socialsItems;
  }
}
