



























import { Component, Vue } from "vue-property-decorator";

interface NavItem {
  title: string;
  link: string;
}

@Component
export default class NavOverlay extends Vue {
  navItems: NavItem[] = [
    {
      title: "Start",
      link: "/"
    },
    {
      title: "Menu",
      link: "/menu"
    },
    // {
    //   title: "Klienci",
    //   link: "/klienci"
    // },
    {
      title: "Nasza jakość",
      link: "/nasza-jakosc"
    },
    {
      title: "Nasze centrum",
      link: "/nasze-centrum"
    }
    // {
    //   title: "Galeria",
    //   link: "/galeria"
    // },
    // {
    //   title: "Gdzie dowozimy?",
    //   link: "/dostawa"
    // }
  ];
  get filteredNavItems() {
    const fitleredItems: NavItem[] = [];
    this.navItems.forEach(item => {
      if (this.$route.path !== item.link) {
        fitleredItems.push(item);
      }
    });
    return fitleredItems;
  }

  get socialsItems() {
    return this.$store.getters.socialsItems;
  }
}
