



















import { Vue, Watch, Component } from "vue-property-decorator";
import slideAnimations from "@/utils/slideAnimations";
import { TimelineLite } from "gsap";

@Component
export default class LoadingOverlay extends Vue {
  get overlayLoading() {
    return this.$store.getters.overlayLoading;
  }

  set overlayLoading(value) {
    this.$store.commit("setOverlayLoading", value);
  }

  beforeCreate() {
    document.body.style.overflowY = "hidden";
  }

  mounted() {
    if (this.$route.path !== "/") {
      this.overlayLoading = false;
    }
  }

  @Watch("overlayLoading")
  onLoadAnimation() {
    const timeline1 = new TimelineLite();
    const timeline2 = new TimelineLite();
    timeline1.to(this.$refs.logo, { opacity: 0, y: -50 });
    timeline2.to(this.$refs.spinner, { opacity: 0 });
    slideAnimations.hideElement(this.$refs.overlay);
    setTimeout(() => (document.body.style.overflowY = "visible"), 500);
  }
}
